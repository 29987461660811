const ADMIN_PUSH_NOTIFICATION_SERVICE = "/admin/push_notification_v1";
const ADMIN_SHIPPING_PROVIDER_INVOICE = "/admin/v1/shipping_provider_invoice";
const ADMIN_SHIPPING_PROVIDER_INVOICE_V2 =
  "/admin/shipping_provider_invoice/v2";
const ADMIN_USER_SERVICE = "/admin/api/v1/user";

const SHOP_MANAGEMENT_PARCEL_SERVICE = "/associate/api/v1/parcel";
const SHOP_MANAGEMENT_SHOP_SERVICE = "/associate/api/v1/shop";

const SHOP_MANAGEMENT_PARCEL_SERVICE_V2 = "/associate/parcel/v2";
const SHOP_MANAGEMENT_TRACKING_HISTORY_SERVICE_V2 =
  "/associate/tracking_history/v2";
const SHOP_MANAGEMENT_USER_SERVICE = "/associate/user/v1";

const MANAGEMENT_BAD_PARCEL_EVENT = "/management/v1/bad_parcel_event";
const MANAGEMENT_DELIVERY_ORDER_SERVICE = "/management/api/v1/delivery_order";
const MANAGEMENT_INVOICE_SERVICE = "/management/api/v1/invoice";
const MANAGEMENT_PARCEL_SERVICE = "/management/api/v1/parcel";
const MANAGEMENT_SHIP_TO_HOME_SERVICE = "/management/v1/ship_to_home";
const MANAGEMENT_SHOP_SERVICE = "/management/api/v1/shop";
const MANAGEMENT_TRANSACTION_SERVICE = "/management/api/v1/transaction";

// Bad Parcel Event
export const GET_ALL_BAD_PARCEL_EVENT = (numberOfDay) => ({
  publicRequset: false,
  requestMapping: `${MANAGEMENT_BAD_PARCEL_EVENT}/all?numberOfDay=${numberOfDay}`,
  requestMethod: "GET",
});

export const UPDATE_PROCESS_BAD_PARCEL_EVENT = (badParcelEvent) => ({
  body: JSON.stringify(badParcelEvent),
  publicRequset: false,
  requestMapping: MANAGEMENT_BAD_PARCEL_EVENT,
  requestMethod: "PUT",
});

// Delivery Order
export const GET_ALL_DELIVERY_ORDER = () => ({
  publicRequset: false,
  requestMapping: MANAGEMENT_DELIVERY_ORDER_SERVICE,
  requestMethod: "GET",
});

export const GET_PRINTABLE_DELIVERY_ORDER = (deliveryOrderId) => ({
  publicRequset: false,
  requestMapping:
    MANAGEMENT_DELIVERY_ORDER_SERVICE +
    `/get_printable_delivery_order?deliveryOrderId=${deliveryOrderId}`,
  requestMethod: "GET",
});

export const UPDATE_PARCEL_BATCH_AND_CREATE_DELIVERY_ORDER = (request) => ({
  publicRequset: false,
  requestMapping:
    MANAGEMENT_DELIVERY_ORDER_SERVICE + `/batch_update_create_delivery_order`,
  requestMethod: "POST",
  body: JSON.stringify(request),
});

// invoice
export const CREATE_INVOICE = (invoice) => ({
  publicRequset: false,
  requestMapping: MANAGEMENT_INVOICE_SERVICE,
  requestMethod: "POST",
  body: JSON.stringify(invoice),
});

export const GET_INVOICES = () => ({
  publicRequset: false,
  requestMapping: MANAGEMENT_INVOICE_SERVICE,
  requestMethod: "GET",
});

export const GET_INVOICES_PAGINTATION = (pageRequest, size) => ({
  publicRequset: false,
  requestMapping: `${MANAGEMENT_INVOICE_SERVICE}/pagination?pageRequest=${pageRequest}&size=${size}`,
  requestMethod: "GET",
});

export const GET_PRINTABLE_INVOICE = (invoiceId) => ({
  publicRequset: false,
  requestMapping: MANAGEMENT_INVOICE_SERVICE + `/print?invoiceId=${invoiceId}`,
  requestMethod: "GET",
});

// parcel
export const DELETE_PARCEL = (displayId) => ({
  publicRequset: false,
  requestMapping: MANAGEMENT_PARCEL_SERVICE + `?displayId=${displayId}`,
  requestMethod: "DELETE",
});

export const GET_BAD_PARCEL = (originalTrackingNumber) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/bad_parcel?originalTrackingNumber=${originalTrackingNumber}`,
  requestMethod: "GET",
});

export const GET_PARCEL_BY_DISPLAY_ID = (dispalyId) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/get_parcel_by_display_id?displayId=${dispalyId}`,
  requestMethod: "GET",
});

export const GET_PARCEL_BY_USER_SID = (userSid) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE_V2 +
    `/get_parcels_by_user_sid?userSid=${userSid}`,
  requestMethod: "GET",
});

export const GET_PARCELS_BY_NUMBER = (countryCode, smsNumber) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/get_parcels_by_number?countryCode=${countryCode}&smsNumber=${smsNumber}`,
  requestMethod: "GET",
});

export const GET_PARCELS_BY_SHOP_NUMBER = (shopNumber) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/get_parcels_by_shop_number?shopNumber=${shopNumber}`,
  requestMethod: "GET",
});

export const GET_PARCELS_CURRENT_STATUS_BY_SHOPS = () => ({
  publicRequset: false,
  requestMapping:
    MANAGEMENT_PARCEL_SERVICE + "/get_parcels_current_status_by_shops",
  requestMethod: "GET",
});

export const GET_MANAGEMENT_PARCELS_BY_NUMBER = (countryCode, smsNumber) => ({
  publicRequset: false,
  requestMapping:
    MANAGEMENT_PARCEL_SERVICE +
    `/get_parcels_by_number?countryCode=${countryCode}&smsNumber=${smsNumber}`,
  requestMethod: "GET",
});

export const GET_PARCELS_BY_QR_CODE = (qrCode) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/get_parcels_by_qr_code?pickupCode=${qrCode}`,
  requestMethod: "GET",
});

export const GET_RELEASE_PARCEL_RESPONSE_BY_COUNTRYCODE_SMSNUMBER = (
  countryCode,
  smsNumber
) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/get_release_parcel_response_by_countryCode_smsNumber?countryCode=${countryCode}&smsNumber=${smsNumber}`,
  requestMethod: "GET",
});

export const GET_PARCELS_BY_SHOP_NUMBER_BETWEEN_DATE = (
  shopNumber,
  startDate,
  endDate
) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/get_parcels_by_shop_number_between_date?shopNumber=${shopNumber}&startDate=${startDate}&endDate=${endDate}`,
  requestMethod: "GET",
});

export const RELEASE_PARCEL = (releaseParcelRequest) => ({
  publicRequset: false,
  requestMapping: SHOP_MANAGEMENT_PARCEL_SERVICE + `/release_parcel`,
  requestMethod: "PUT",
  body: JSON.stringify(releaseParcelRequest),
});

export const RELEASE_PARCEL_V2 = (request) => ({
  publicRequset: false,
  requestMapping: SHOP_MANAGEMENT_PARCEL_SERVICE_V2 + `/release_parcels`,
  requestMethod: "PUT",
  body: JSON.stringify(request),
});

export const RESET_PARCEL_PICKUP_DATE = (parcel) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE + "/reset_parcel_ready_to_pickup_date",
  requestMethod: "PUT",
  body: JSON.stringify(parcel),
});

export const CREATE_PARCEL = (parcel) => ({
  publicRequset: false,
  requestMapping: MANAGEMENT_PARCEL_SERVICE,
  requestMethod: "POST",
  body: JSON.stringify(parcel),
});

export const CREATE_BAD_PARCEL = (parcel) => ({
  publicRequset: false,
  requestMapping: MANAGEMENT_PARCEL_SERVICE + "/bad_parcel",
  requestMethod: "POST",
  body: JSON.stringify(parcel),
});

export const CREATE_PARCEL_PRIOR_CHECK = (parcel) => ({
  publicRequset: false,
  requestMapping: MANAGEMENT_PARCEL_SERVICE + "/create_parcel_prior_check",
  requestMethod: "POST",
  body: JSON.stringify(parcel),
});

export const GET_PARCELS_BY_PARCEL_STATUS = (parcelStatus) => ({
  publicRequset: false,
  requestMapping:
    MANAGEMENT_PARCEL_SERVICE + `/parcel_status?parcelStatus=${parcelStatus}`,
  requestMethod: "GET",
});

// DEPRECATED
export const GET_PARCELS_RECOREDS = (shopNumber, day) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/get_parcels_records?shopNumber=${shopNumber}&day=${day}`,
  requestMethod: "GET",
});

export const GET_PARCELS_RECOREDS_PAGINATION = (
  shopNumber,
  pageRequest,
  size
) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/get_parcels_records_pagination?shopNumber=${shopNumber}&pageRequest=${pageRequest}&size=${size}`,
  requestMethod: "GET",
});

export const MATCH_BAD_PARCEL = (
  originalTrackingNumber,
  countryCode,
  smsNumber,
  shopNumber
) => ({
  publicRequset: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE_V2 +
    `/match_bad_parcel?originalTrackingNumber=${originalTrackingNumber}&receiverCountryCode=${countryCode}&receiverSmsNumber=${smsNumber}&shopId=${shopNumber}`,
  requestMethod: "POST",
});

export const SEND_INTERNAL_PARCEL_INFO_UPDATED_PUSH_NOTIFICATION = (
  parcel
) => ({
  publicRequest: false,
  requestMapping:
    MANAGEMENT_PARCEL_SERVICE + "/parcel_info_update_push_notification",
  requestMethod: "POST",
  body: JSON.stringify(parcel),
});

export const STORE_RECEIVED_PARCEL_BY_ORIGINAL_TRACKING_NUMBER = (
  originalTrackingNumber
) => ({
  publicRequest: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE_V2 +
    `/store_receive?originalTrackingNumber=${originalTrackingNumber}`,
  requestMethod: "PUT",
});

export const UPDATE_PARCEL = (parcelRequest, trackingHistoryType) => ({
  publicRequest: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE_V2 +
    `?trackingHistoryType=${trackingHistoryType}`,
  requestMethod: "PUT",
  body: JSON.stringify(parcelRequest),
});

export const UPDATE_PARCEL_STORE_RECEIVED = (shopNumber, displayId) => ({
  publicRequest: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/store_received_parcel_by_displayId?shopNumber=${shopNumber}&displayId=${displayId}`,
  requestMethod: "PUT",
});

export const UPDATE_PARCEL_STORE_RECEIVED_ORIGINAL_TRACKING_NUMBER = (
  shopNumber,
  originalTrackingNumber
) => ({
  publicRequest: false,
  requestMapping:
    SHOP_MANAGEMENT_PARCEL_SERVICE +
    `/store_received_parcel_by_original_tracking_number?shopNumber=${shopNumber}&originalTrackingNumber=${originalTrackingNumber}`,
  requestMethod: "PUT",
});

export const UPDATE_PARCEL_BATCH = (request) => ({
  publicRequest: false,
  requestMapping: MANAGEMENT_PARCEL_SERVICE + "/batch_update",
  requestMethod: "PUT",
  body: JSON.stringify(request),
});

// Shipping Provider Invoice
export const GET_PRINTABLE_SHIPPING_PROVIDER_INVOICE = (invoiceId) => ({
  publicRequest: false,
  requestMapping: `${ADMIN_SHIPPING_PROVIDER_INVOICE_V2}/print?invoiceId=${invoiceId}`,
  requestMethod: "GET",
});

export const GET_SHIPPING_PROVIDER_INVOICE_PAGINATION = (
  pageRequest,
  size
) => ({
  publicRequest: false,
  requestMapping: `${ADMIN_SHIPPING_PROVIDER_INVOICE}/pagination?pageRequest=${pageRequest}&size=${size}`,
  requestMethod: "GET",
});

// Ship To Home
export const GET_SHIP_TO_HOME_PAGINATION = (pageRequest, size) => ({
  publicRequest: false,
  requestMapping: `${MANAGEMENT_SHIP_TO_HOME_SERVICE}/pagination?pageRequest=${pageRequest}&size=${size}`,
  requestMethod: "GET",
});

export const UPDATE_SHIP_TO_HOME = (shipToHome) => ({
  body: JSON.stringify(shipToHome),
  publicRequest: false,
  requestMapping: MANAGEMENT_SHIP_TO_HOME_SERVICE,
  requestMethod: "PUT",
});

// PUSH NOTIFICATION
export const SEND_GENERAL_PUSH_NOTIFICATION = (request) => ({
  body: JSON.stringify(request),
  publicRequest: false,
  requestMapping:
    ADMIN_PUSH_NOTIFICATION_SERVICE + "/general_push_notification",
  requestMethod: "POST",
});

// SHOP
export const CREATE_SHOP = (shop) => ({
  publicRequest: false,
  requestMapping: MANAGEMENT_SHOP_SERVICE,
  requestMethod: "POST",
  body: JSON.stringify(shop),
});

export const GET_MANAGEABLE_SHOPS = () => ({
  publicRequest: false,
  requestMapping: SHOP_MANAGEMENT_SHOP_SERVICE,
  requestMethod: "GET",
});

// this return ShopDap for management | admin to use only
export const GET_MANAGEMENT_SHOPS = () => ({
  publicRequest: false,
  requestMapping: MANAGEMENT_SHOP_SERVICE + "/get_shops",
  requestMethod: "GET",
});

export const GET_SHOP_CURRENT_STATUS = (shopNumber) => ({
  publicRequest: false,
  requestMapping: `${SHOP_MANAGEMENT_SHOP_SERVICE}/current_status?shopNumber=${shopNumber}`,
  requestMethod: "GET",
});

export const UPDATE_SHOP_DAO = (shop) => ({
  publicRequest: false,
  requestMapping: MANAGEMENT_SHOP_SERVICE,
  requestMethod: "PUT",
  body: JSON.stringify(shop),
});

// Tracking history
export const GET_TRACKING_HISTORY = (parcelId) => ({
  publicRequest: false,
  requestMapping: `${SHOP_MANAGEMENT_TRACKING_HISTORY_SERVICE_V2}/${parcelId}`,
  requestMethod: "GET",
});

// Transaction
export const GET_TRANSACTION_SUMAMRY = (startDate, endDate) => ({
  publicRequest: false,
  requestMapping: `${MANAGEMENT_TRANSACTION_SERVICE}?startDate=${startDate}&endDate=${endDate}`,
  requestMethod: "GET",
});

// User Profile
export const CHANGE_PASSWORD = (updateProfileRequest) => ({
  publicRequest: false,
  requestMapping: "/user_profile/v1/change_password",
  requestMethod: "PUT",
  body: JSON.stringify(updateProfileRequest),
});

export const GET_USERS = (pageRequest) => ({
  publicRequest: false,
  requestMapping: `${ADMIN_USER_SERVICE}/get_users?pageRequest=${pageRequest}`,
  requestMethod: "GET",
});

export const GET_USER_BY_COUNTRY_CODE_AND_SMS_NUMBER = (
  countryCode,
  smsNumber
) => ({
  publicRequest: false,
  requestMapping: `${SHOP_MANAGEMENT_USER_SERVICE}/${countryCode}/${smsNumber}`,
  requestMethod: "GET",
});

export const GET_USER_BY_SID = (sid) => ({
  publicRequest: false,
  requestMapping: `${SHOP_MANAGEMENT_USER_SERVICE}/sid/${sid}`,
  requestMethod: "GET",
});

export const CREATE_PARCEL_BATCH = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return {
    publicRequest: false,
    requestMapping: `${MANAGEMENT_PARCEL_SERVICE}/create_parcel_batch`,
    requestMethod: "POST",
    body: formData,
    notJsonRequest: true,
  };
};

import { GET_SHOPS } from "online-shopping-cargo-parent/dist/service";

export default class AppStateService {
  _appState;
  _serviceExecutor;

  constructor(appState, serviceExecutor) {
    this._appState = appState;
    this._serviceExecutor = serviceExecutor;
  }

  async getShop(force = false) {
    const { dirty, content } = this._appState.shop;
    return new Promise((resolve, reject) => {
      if (dirty || force) {
        this._serviceExecutor.execute(GET_SHOPS()).then((shops) => {
          this._appState.shop.setShop(shops);
          return resolve(shops);
        });
      } else {
        return resolve(content);
      }
    });
  }
}

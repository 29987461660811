import ApplicationComponent from "online-shopping-cargo-parent/dist/applicationComponent";
import applicationContext from "online-shopping-cargo-parent/dist/applicationContext";
import { GET_USER_PROFILE } from "online-shopping-cargo-parent/dist/service";
import { GET_MANAGEABLE_SHOPS } from "../common/adminService";
import { CargoManagementContext } from "../context/contextProvier";
import AdminPanelApplicationContext from "./AdminPanelApplicationContext";
import AppStateService from "./appStateService";

export default class AdminPanelApplicationComponent extends ApplicationComponent {
  _adminPanelApplicationContext = new AdminPanelApplicationContext();
  static contextType = CargoManagementContext;
  _appStateService;

  state = {
    ...this.state,
    loading: false,
    confirmModal: {
      body: "",
      header: "",
      onClickConfirm: () => {},
      show: false,
    },
  };

  toLoginPage() {
    this.props.history.push("/login");
  }

  urlPrefix() {
    return applicationContext.mock ? "/" : "/admin-panel";
  }

  async getManageableShops() {
    if (this.appState.manageableShop.dirty) {
      const shops = await this.serviceExecutor.execute(GET_MANAGEABLE_SHOPS());
      this.appState.manageableShop.setShops(shops);
      this.appState.manageableShop.setSelectedShop(shops[0]);
    }
  }

  goTo(pathname, params = {}) {
    this.props.history.push({
      pathname,
      state: params,
    });
  }

  async validatePermission() {
    // check if token is present
    if (!this.storage.getUserToken()) {
      return this.toLoginPage();
    }

    let userProfile = await this.serviceExecutor.execute(GET_USER_PROFILE());
    if (!userProfile || userProfile.authorities.includes("ROLE_USER")) {
      this.storage.removeUserToken();
      return this.toLoginPage();
    } else {
      this.appState.userProfile.setUserInfo(userProfile);
      this.getManageableShops();
      this.appStateService.getShop();
    }
  }

  onCloseConfirmModal = () => {
    this.setState({
      confirmModal: {
        show: false,
      },
    });
  };

  get applicationContext() {
    return this._adminPanelApplicationContext;
  }

  get appState() {
    return this.context;
  }

  get appStateService() {
    if (!this._appStateService) {
      this._appStateService = new AppStateService(
        this.context,
        this.serviceExecutor
      );
    }
    return this._appStateService;
  }

  get routerProps() {
    return this.props?.location?.state;
  }
}

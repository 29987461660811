import React, { Component } from "react";

export const CargoManagementContext = React.createContext();

export class ContextProvider extends Component {
  state = {
    modal: { body: "", header: "", show: false },
    manageableShop: {
      dirty: true,
      selectedShop: {},
      shops: [],
    },
    shop: {
      dirty: true,
      content: [],
    },
    userProfile: {
      dirty: true,
      userInfo: {},
    },
  };

  render() {
    const { manageableShop, modal, shop, userProfile } = this.state;

    return (
      <CargoManagementContext.Provider
        value={{
          modal: {
            ...modal,
            setModal: this.setModel,
          },
          manageableShop: {
            ...manageableShop,
            setShops: this.setShops,
            setSelectedShop: this.setSelectedShop,
          },
          shop: {
            ...shop,
            setShop: this.setShop,
          },
          userProfile: {
            ...userProfile,
            setUserInfo: this.setUserInfo,
          },
        }}
      >
        {this.props.children}
      </CargoManagementContext.Provider>
    );
  }

  setSelectedShop = (selectedShop) => {
    this.setState((state) => ({
      manageableShop: {
        ...state.manageableShop,
        selectedShop,
      },
    }));
  };

  setShop = (shop) => {
    this.setState((state) => ({
      shop: {
        dirty: false,
        content: shop,
      },
    }));
  };

  setShops = (shops) => {
    this.setState({
      manageableShop: {
        dirty: false,
        shops,
      },
    });
  };

  setModel = (modal) => {
    this.setState({
      modal,
    });
  };

  setUserInfo = (profile) => {
    this.setState({
      userProfile: {
        dirty: false,
        userInfo: profile,
      },
    });
  };
}

export const Consumer = CargoManagementContext.Consumer;
